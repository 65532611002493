import type { ExternalProjectType } from '~/components/external-project/ManageExternalProject';
import type { ProjectCardProps } from '~/components/shared/ProjectCard';
import type { Project } from '~/models/project.server';
import { CDN_URL, EDITOR_URL } from './constants';

export const images = [
  'https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/external-project-1.png?v=1717706812454',
  'https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/external-project-2.png?v=1717706812851',
  'https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/external-project-3.png?v=1717706813245',
  'https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/external-project-4.png?v=1717706813613',
  'https://cdn.glitch.global/605e2a51-d45f-4d87-a285-9410ad350515/external-project-5.png?v=1717706814070',
];

export const determinePlaceholderImage = (index: number) => {
  if (index >= images.length) {
    return images[index % images.length];
  }
  return images[index];
};

export const formatProjectForCard = (project: Project) => {
  return {
    description: project.description,
    href: `/project/${project.domain}`,
    id: project.id,
    imgSrc: `${CDN_URL}/project-avatar/${project.id}.png?${project.avatarUpdatedAt}`,
    isPrivate: project.private,
    remixHref: `${EDITOR_URL}#!/${project.domain}`,
    title: project.domain,
  } as ProjectCardProps;
};

export const formatExternalProjectForCard = (
  project: ExternalProjectType,
  index: number,
) => {
  return {
    description: project.description,
    href: project.url,
    id: project.id,
    imgSrc: project.imageUrl || determinePlaceholderImage(index),
    title: project.title || project.url,
    verified: project.verifiedOwnership,
  } as ProjectCardProps;
};
